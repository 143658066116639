import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperMarquee } from '../utils/swiperMarquee.utils.js';

let clientsSwiperWr = document.querySelectorAll('.clients-swiper-wr');
clientsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 0,
      slidesPerView: 'auto',
      threshold: 10,
      loop: slidesCount > 5,
      loopedSlides: slidesCount > 5 ? slidesCount * 2 : false,
    });

    swiperMarquee(swiper, {
      speed: window.innerWidth >= 768 ? 0.77 : 0.5,
    });
  }
});
