import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
let ourTeamSwiperWr = document.querySelectorAll('.our-team-swiper-wr');
ourTeamSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 18,
      slidesPerView: 1,
      threshold: 10,
      loop: slidesCount > 5 ? true : false,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 30,
          slidesPerView: 2.5,
        },
        1440: {
          slidesPerView: 4,
          spaceBetween: 30,
        }
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      on:{
      afterInit: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        },
        resize: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        }
      }
    });
    swiperObserver(swiper);
  }
});