import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utils.js';

import './components/buttons.component.js';
import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/animations.component.js';
import './components/accordion.component.js';
import './components/date.component.js';
// import './components/jobs-search.component.js';
import './components/player.component.js';
import './components/sectors-accordion.component.js';
import './components/job-search.component.js';
// import './components/faq.component.js';
// import './components/hiring.component.js';
// import './components/process.component.js';
//
// import './components/marquee.component.js';

import './components/sectors.component.js';
import './components/insights.component.js';

/*Sliders*/
import './components/sw-clients.component.js';
import './components/sw-hiring.component.js';
import './components/sw-trust-more.component.js';
import './components/sw-our-team.component.js';
import './components/sw-first-section.component.js';
import './components/sw-live-jobs.component.js';
import './components/sw-gallery.component.js';
import './components/sw-stories.component.js';
import './components/sw-partners.component.js';
import './components/sw-award-winning.component.js';
import './components/sw-location.component.js';
/*Sliders End*/

import './components/overlayScroll.component.js';

import './libs/countUp.lib.js';

window.jsScrollToEl = function jsScrollToEl(targetID) {
  let config = targetID.split('|');
  let duration = +config[1] && +config[1] !== '' ? +config[1] : 800;
  let paddingTop = +config[2] && +config[2] !== '' ? +config[2] : 0;
  let target = config[0];
  let targetElement = document.querySelector(target);

  if (targetElement) {
    setTimeout(() => {
      let targetOffsetTop = targetElement.getBoundingClientRect().top + window.scrollY - paddingTop;
      window.scrollTo({
        top: targetOffsetTop - 50,
        behavior: 'smooth',
      });
    }, 700);
    return false;
  }
};

